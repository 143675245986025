import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { CheckIcon } from "@heroicons/react/outline"

const DashboardPage = () => {
  const features = [
    {
      name: "降本增效可视化",
      description: "统计任务完成情况，节省时间可视化，一目了然",
    },
    {
      name: "状态全面监控",
      description: "机器人运行状态全面掌握，图表动画展示",
    },
    {
      name: "多维角度统计",
      description: "多维度汇总数据，自动定时生成报表，日志可追溯",
    },
    {
      name: "错误报警排查",
      description: "及时发送消息通知，直接定位问题，快速排查",
    },
    {
      name: "灵活多角色",
      description: "根据组织架构，按需分配角色权限",
    },
    {
      name: "多端协作",
      description: "同时下发任务给多台机器人，协同并行处理任务",
    },
    {
      name: "多场景触发",
      description: "定时作业，API接口，外部事件触发任务",
    },
    {
      name: "灵活部署",
      description: "兼容主流系统，支持公有云、私有云",
    },
  ]

  return (
    <Layout>
      <Seo title="IBRPA驾驶舱" />
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="bg-indigo-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">IBRPA驾驶舱</span>
                <span className="block"></span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-indigo-200">
                RPA机器人工作可视化，监控中心
              </p>
              <Link
                to="/trial"
                className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-indigo-600 hover:bg-indigo-50"
              >
                免费试用
              </Link>
            </div>
          </div>
          <div className="py-8 lg:py-12">
            <StaticImage
              className="rounded-md object-cover object-left-top"
              src="../../images/驾驶舱.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">功能特点</h2>
          <p className="mt-4 text-lg text-gray-500"></p>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          {features.map(feature => (
            <div key={feature.name} className="relative">
              <dt>
                <CheckIcon
                  className="absolute h-6 w-6 text-green-500"
                  aria-hidden="true"
                />
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 ml-9 text-base text-gray-500">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </Layout>
  )
}

export default DashboardPage
